<template>
  <div class="base" dropzone>
    <v-container class="contcont">
      <v-row v-for="(item, i) in items" :key="'item-' + i">
        <v-col class="colcol" v-for="(sub, j) in item" :key="'sub-' + j">
          <v-textarea hide-details="" v-model.lazy="sub.val">sadada</v-textarea>
        </v-col>
      </v-row>
      <!-- <v-row >
        <v-col class="colcol" >
          <v-textarea hide-details="" v-model="items[0]['val1']">sadada</v-textarea>
        </v-col>
      </v-row> -->
    </v-container>


    <div dropzone>
dsadsadsadsadsa<br>
dsadsadsadsadsa<br>
dsadsadsadsadsa<br>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        [
          {val: null},
          {val: null},
          {val: null},
        ],
        [
          {val: null},
          {val: null},
          {val: null},
        ],
        [
          {val: null},
          {val: null},
          {val: null},
        ],
      ]
      // items: [
      //   {
      //     val1: null,
      //     val2: null,
      //   },

      // ],
    }
  },
  watch: {
    items: {
      handler() {
        console.log(this.items)
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.base {
  width: 100%;
  overflow: scroll;
}
.contcont {
  width: 2000px;
  max-width: 2000px;
}

.colcol {
  border: 1px solid black;
  width: 1000px;
  background-color: white;
}
.colcol:first-child {
  position: sticky;
  left: 0;
  z-index: 9999;
}

</style>